// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-about-page-js": () => import("/builds/primechap/info-web-content/core/src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-career-page-js": () => import("/builds/primechap/info-web-content/core/src/templates/career-page.js" /* webpackChunkName: "component---src-templates-career-page-js" */),
  "component---src-templates-index-page-js": () => import("/builds/primechap/info-web-content/core/src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-innovation-page-js": () => import("/builds/primechap/info-web-content/core/src/templates/innovation-page.js" /* webpackChunkName: "component---src-templates-innovation-page-js" */),
  "component---src-templates-investor-page-js": () => import("/builds/primechap/info-web-content/core/src/templates/investor-page.js" /* webpackChunkName: "component---src-templates-investor-page-js" */),
  "component---src-templates-about-sub-page-js": () => import("/builds/primechap/info-web-content/core/src/templates/about-sub-page.js" /* webpackChunkName: "component---src-templates-about-sub-page-js" */),
  "component---src-templates-our-business-page-js": () => import("/builds/primechap/info-web-content/core/src/templates/our-business-page.js" /* webpackChunkName: "component---src-templates-our-business-page-js" */),
  "component---src-templates-article-roll-js": () => import("/builds/primechap/info-web-content/core/src/templates/article-roll.js" /* webpackChunkName: "component---src-templates-article-roll-js" */),
  "component---src-templates-article-post-js": () => import("/builds/primechap/info-web-content/core/src/templates/article-post.js" /* webpackChunkName: "component---src-templates-article-post-js" */),
  "component---src-pages-404-js": () => import("/builds/primechap/info-web-content/core/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-index-js": () => import("/builds/primechap/info-web-content/core/src/pages/articles/index.js" /* webpackChunkName: "component---src-pages-articles-index-js" */),
  "component---src-pages-contact-index-js": () => import("/builds/primechap/info-web-content/core/src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/builds/primechap/info-web-content/core/.cache/data.json")

